<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="form" :model="form" label-width="100px" :inline="true">
        <el-form-item>
          <!-- <el-button type="primary" @click="currentPage=1;query(pageSize,0)">查詢</el-button> -->
          <el-button type="primary" icon="el-icon-plus" @click="addCategory">新增分類</el-button>
        </el-form-item>
      </el-form>
    </CRow>
    <CRow >
      <el-tree :data="categories" :props="defaultProps" style="width:80%;"
      :expand-on-click-node="false" @node-click="handleNodeClick">
         <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
          <el-button
            type="text"
            size="mini"
            @click="appendCategory(data)"
            >
            新增子分類
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="editCategory(data)"
            >
            編輯
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="deleteCategory(data.id)"
           >
            刪除
          </el-button>
        </span>
         </span>
      </el-tree>
    </CRow>
    <el-dialog title="分類" :visible.sync="dialogFormVisible"  v-loading="loading">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="名稱" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分類" :label-width="formLabelWidth" prop="parent">
          <el-cascader :props="props" v-model="form.parent" :options="categories" :disabled="form.id > 0"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">確 定</el-button>
      </div>
  </el-dialog>
  
   
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import moment from "moment-timezone";
import API from '@/api/'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'CategoryList',
  created () {
      this.$store.dispatch('category/list', {max: this.pageSize, offset: 0}, {
          root: true
      });
  },
  data() {
      
        var validateName = (rule, value, callback) => {
            const category = this.form.parent.length > 0 ? this.form.parent[this.form.parent.length-1]: null;

            API.category.validateName({category, name: value, id: this.form.id}).then(res =>{
              if (res.data.isOK) {
                  if (this.form.id) {
                      const data = this.getCategoryById(this.form.id);
                      if ( data.name === value) {
                        callback();
                        return;
                      }
                  } 
                  callback();
              } else {
                callback(new Error(res.data.message));
              } 
            })
           
            
           
          

        };
        var validateSubName = (rule, value, callback) => {
            if (this.form2.id) {
                 const data = this.getSubCategoryById(this.form2.id);
                 if ( data.name === value) {
                   callback();
                   return;
                 }
            } 
            const category = this.form.parent.length > 0 ? this.form.parent[this.form.parent.length-1]: null;
            API.subCategory.validateName({category  , name: value}).then(res =>{
              if (res.data.isOK) {
                  callback();
              } else {
                callback(new Error(res.data.message));
              } 
            })
          

        };
        return {
          loading: false,
          pageSize:10,
          currentPage:0,
          dialogFormVisible: false,
          dialogSubCategoryFormVisible: false,
          dialogSubCategoryListVisible: false,
          form: {
            id: null,
            name: '',
            parent: []
          },
          defaultProps: {
            label: 'name',
          },
          form2: {
            id: null,
            category: '',
            name: '',
          },
          formLabelWidth: '120px',
          selectedCategoryId: null,
          rules: {
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
              { validator: validateName, trigger: 'blur' },
            ],
          },
          rules2: {
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
              { validator: validateSubName, trigger: 'blur' },
            ],
          },
          props: {
            value: 'id',
            label: 'name',
            checkStrictly: true
          },
        }
  },
  methods:{
    handleNodeClick(data) {
      console.log(data);
      },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1));
      },
    query(max,offset) {
      this.$store.dispatch('category/list', {max: max, offset: offset}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
    addCategory() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.form.id = null;
      this.form.name = '';
      this.form.parent = [];
      this.dialogFormVisible = true
    },
    appendCategory(data) {
     
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.form.id = null;
      this.form.name = '';
      API.category.ancestors(data.id).then(res=>{
          this.form.parent = res.data.map(d=>d.id);
          this.dialogFormVisible = true
      })
      
    },
    addSubCategory() {
      this.form2.category = this.selectedCategoryId;
      this.form2.id = null;
      this.form2.name = '';
      this.dialogSubCategoryFormVisible = true
    },
    editCategory(data) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
       this.form.id = data.id;
       this.form.name = data.name;
        API.category.ancestors(data.id).then(res=>{
          this.form.parent = res.data.map(d=>d.id);
          this.form.parent.pop();
          this.dialogFormVisible = true
      });
    },
    deleteCategory(catetory_id) {
      this.$confirm('此操作將永久刪除該分類, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('category/remove', catetory_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    editSubCategory(subCatetory_id) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
       const data = this.getSubCategoryById(subCatetory_id);
       this.form2.id = data.id;
       this.form2.name = data.name;
       this.form2.category = this.selectedCategoryId;
       this.dialogSubCategoryFormVisible = true;
    },
    deleteSubCategory(subCatetory_id) {
      this.$confirm('此操作將永久刪除該分類, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('subCategory/remove', subCatetory_id , {
            root: true
          }).then( res => {
            this.$store.dispatch('subCategory/list', { category_id: this.selectedCategoryId ,max: 100, offset: 0} , {
            root: true
          })
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    submit() {
        this.loading = true;
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
             this.loading = false;
            return false;
          }
        });
      
    },
    submitAdd() {
      
      this.$store.dispatch('category/add', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.name = '';
        this.form.id = null;
      }).finally(()=>{
         this.loading = false;
      })
    },
    submitUpdate() {
      this.$store.dispatch('category/update', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.name = '';
        this.form.id = null;
      }).finally(()=>{
         this.loading = false;
      })
    },
    submitSubCategory() {
        this.$refs['form2'].validate((valid) => {
          if (valid) {
              if(this.form2.id) {
                this.submitSubCategoryUpdate();
              } else {
                this.submitSubCategoryAdd();
              }
          } else {
            return false;
          }
        });
    },
    submitSubCategoryUpdate() {
      this.$store.dispatch('subCategory/update', this.form2 , {
          root: true
      }).then( res => {
        this.dialogSubCategoryFormVisible = false;
        this.form2.name = '';
        this.$store.dispatch('subCategory/list', { category_id: this.selectedCategoryId ,max: 100, offset: 0} , {
          root: true
        });
      })
    },
    submitSubCategoryAdd() {
      this.$store.dispatch('subCategory/add', this.form2 , {
          root: true
      }).then( res => {
        this.dialogSubCategoryFormVisible = false;
        this.form2.name = '';
        this.$store.dispatch('subCategory/list', { category_id: this.selectedCategoryId ,max: 100, offset: 0} , {
          root: true
        });
      })
    },
    showSubCategoryList(category_id) {
      this.selectedCategoryId = category_id;
      this.$store.dispatch('subCategory/list', { category_id: this.selectedCategoryId ,max: 100, offset: 0} , {
          root: true
      }).then(res => {
        this.dialogSubCategoryListVisible = true;
      })
    },
  },

  computed: {
      ...mapGetters({
          // 取得資料夾列表
          categories: 'category/categories',
          total:'category/total',
          max:'category/max',
          offset:'category/offset',
          subCategories: 'subCategory/subCategories',
          getCategoryById: 'category/getCategoryById',
          getSubCategoryById: 'subCategory/getSubCategoryById'
      })
  },
}
</script>

<style scoped>
.el-cascader {
    width: 350px;
  }
  .tag_group .el-tag+.el-tag {
    margin-left: 10px;
  }
    .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>
